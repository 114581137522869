/* liste des solution*/
.solution-wrapper{
    height: auto;
    margin: 10vh auto;
    font-family: 'Roboto', sans-serif;
}
.solution-wrapper .table-header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.solution-wrapper .table-header .table-title{
    color: #2e9662;
    font-weight: 100;
}

.footer-space{
    position: relative !important ;
    margin-top: 200px !important;
  }

/*Add application**/
