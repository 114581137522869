
/*----------step-------------*/
.bg-color{
	background-color: #333;
}
.signup-step-container{
	padding: 150px 0px;
	padding-bottom: 60px;
}

    .wizard .nav-tabs {
        position: relative;
        margin-bottom: 0;
        border-bottom-color: transparent;
    }

    .wizard > div.wizard-inner {
        position: relative;
    }

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 75%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
}

.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 16px;
    color: #0e214b;
    font-weight: 500;
    border: 1px solid #ddd;
}
span.round-tab i{
    color:#555555;
}
.wizard li.active span.round-tab {
        background: #2e9662;
    color: #fff;
    border-color: #2e9662;
}
.wizard li.active span.round-tab i{
    color: #5bc0de;
}
.wizard .nav-tabs > li.active > a i{
	color: #2e9662;
}

.wizard .nav-tabs > li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: red;
    transition: 0.1s ease-in-out;
}



.wizard .nav-tabs > li a {
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    position: relative;
    top: 0;
}
.wizard .nav-tabs > li a i{
	position: absolute;
    top: -15px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 700;
    color: #000;
}

    .wizard .nav-tabs > li a:hover {
        background: transparent;
    }

.wizard .tab-pane {
    position: relative;
    padding-top: 20px;
}




.isrequired{
    color: rgb(255, 0, 0);
}

.widzard{
  max-width: 900px;
  width: 100%;
  height: auto;
  margin: 10vh auto;
  padding: 30px;
  box-shadow: 0px 2px 5px #ccc;
  font-family: 'Roboto', sans-serif;
}


.widzard-items .head .title{
  margin-bottom: 25px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}


.widzard .widzard-footer{
  margin-top: 30px;
 border-top: #a49e9e5e solid 1px;

}

.widzard .form .input_field{
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.widzard .form .input_field .control{
width: 100%;
}

.widzard .form .input_field label{
 width: 60%;
 margin-right: 10px;
 font-size: 14px;  
}

@media all and (max-width:629px) {
  .widzard .form .input_field{
  flex-wrap: wrap;
  } 
  .widzard .form .input_field label{
      width: 100%;  
      
   }
}

.widzard .form .input_field input ,.widzard .form .input_field select{
 width:100%;
 outline: none;
 border: 0.6px solid;
 font-size: 15px;
 padding: 8px 10px;
 border-radius: 3px;
 transition: all 0.3 ease;

}
.widzard .form .input_field .input:focus
{
  border: 1px solid #2e9662;

}
.widzard .form .input_field p{
  font-size: 14px;
  color: #000000;
}


.widzard .form .inputfield:last-child{
  margin-bottom: 0;
}

@media (max-width:420px) {
  .widzard .form .inputfield{
    flex-direction: column;
    align-items: flex-start;
  }
  .widzard .form .inputfield label{
    margin-bottom: 5px;
  }

}


.widzard-error .footer a{
color: #a91414;
font-size: 15px;
text-decoration: none;
font-weight: bold;
}




/*Nex prev btn*/
.widzard-btn{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around ;
}
.widzard-btn .next button{
  color: #fff;
  text-align: center;
}
.widzard-btn .prev button{
  color: #fff;

}


.widzard-items .succesLogo img{
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}
.widzard-items .succesLogo{
  display: flex;
  flex-direction: row;
  justify-content: center;

}





/*      */

