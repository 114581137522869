.headerH1TextStyle{
    text-align: center;
    margin-top: 110px;
    margin-bottom: 25px;
    font-family: 'Rubik',sans-serif;
    color: #2E9662;
    font-weight: bold;
}


@media screen and (max-width:1025px) {
 

    .headerH1TextStyle{
        text-align: center;
        margin-top: 110px;
        margin-bottom: 25px;
        font-family: 'Rubik',sans-serif;
        color: #2E9662;
        font-weight: bold;
    }
  }

@media (max-width:767px) {


    #items li a 
    {
        text-decoration: none;
        color: #36383F;
        transition: all 250ms ease;
        font-weight: normal;
        font-size: medium;
    }
    
  

  
}



/* -------version ordinateur  */


@media (min-width: 767.01px) {
   #checkboxheader
    {
        display: none;
    }
  
    #items
    {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    #items-content
    {
        background-color: red;
        width: 100%;
    }
    #items li
    {
        float: right;
        padding: 20px;
        font-weight: normal;
    }
    #items li a 
    {
        text-decoration: none;
        color: #36383F;
        font-family: 'Roboto', sans-serif;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: medium;
        transition: all 250ms ease;
    }
    #items li a:hover 
    {
        color: #2e9662;
    }

}




/*===========================================  A C C O R D É O N ===================================================*/

/*===========================================  A C C O R D É O N ===================================================*/

.d-none {
    display: none;
}

.d-block {
    display: block;
    background-color: #ffffff9e;
    border-width: 0px 1px 1px;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-color: #2e9662;
    border-bottom-color: #2e9662;
    border-left-color: #2e9662;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

ul {
    list-style:none;
} 
  
#accordion button:focus{
    border-radius:5px;
    outline:none;
}

#accordion button.active{
    background-color: #2e9662;
    color: #fff;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
#accordion button::after{
    color: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

p {
    padding: 0 1.2rem ;
}

#accordion button{
    outline: none;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;   
    border: 2px solid #2e9662;
    border-radius: 7px !important;
    color: #000;
    width: 100%;
    text-align:left;
    font-size:16px;
    border-radius:0px;
}

#accordion li{  
    /* border: 1px solid #ccc; */
    margin-bottom: 20px;
}

.acc-item:last-child {
    border-bottom: 1px solid #ccc;
}

#accordion button::after{
    content: "\002B";
    font-weight: 400;
    font-size: 22px;
    float:right;
    color: black;
}

#accordion{
    width:80%;
    max-width: 800px;
    min-width: 275px;
    margin:auto;
    margin-top: 80px;
    
}

.visualEffectDivShade ::after {
    border-top: 0;
    border-right-color: #8A8E8A;
    border-bottom-color: #8A8E8A;
    border-left-color: #8A8E8A;    
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.acc-item-content{
    padding: 0px 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;   
}


#accordion button.active:after{
    content: "\2212";
    font-weight: 900;
    font-size: 22px;
    float:right;
}


