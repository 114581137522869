
.amountShape{
  font-family: 'Roboto Condensed';
  text-align: center;
  font-weight:300;
  font-size: 25px;
  margin-top: 2.5rem;
  width: 100%;
  color: #DF5948;
}

#img 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8%;
    padding-bottom: 8%;
}
#img img 
{
    height: 50px;
    width: 50px;
}

/*---------------------- others ---------------------------*/

#form
{
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

#wow{
    height: 80vh;
    display: flex;
    flex-direction: row;
    align-items: center;  
}

#wow form
{
    width: 100%;
    border: 1px solid inherit;
    background-color: white;
    box-shadow: 0px 5px 20px #ccc;
}

.rintio{
    text-align: center;
    margin-top: -30px;
    font-weight: bold;
    display: flex;
    
}
/*#menu.links_page{
  margin-bottom: 0;
  margin-right: 27px;
}
.links_page li{
  margin: auto 10px auto 0;
}

.links_page li a {
  text-decoration: none;
  color: #36383F;
  transition: all 250ms ease;
  font-weight: normal;
  font-size: medium;
  
}

.links_page li a:hover 
{
    color: #2e9662;
}

@media (max-width: 370px) {
  #imgShape {
    margin-left: 60px !important;
  }
}
*/

/*
.card-header
{
   margin-bottom: 10px;
    
}

#form-header
{
    background-color: #00467f;
    color: #fff;
    padding: 10px;
    text-align: center;
    width: 100%;
    
}
#logo
{
    width: 81px;
    margin-left: auto;
    margin-right: auto;
}

*/

.form-item-text {
  text-align: center;
  font-size: medium;
  width: 100%;
}

.amountShape{
  font-family: 'Roboto Condensed';
  text-align: center;
  font-weight:300;
  font-size: 25px;
  margin-top: 2.5rem;
  width: 100%;
  color: #DF5948;
}

.underTextFailed {
  text-align: center;
  color: #DF5948;
}

.underTextSucces {
  text-align: center;
  color: #2e9662 ;
}

.title_card{
   
    color: #2e9662;
    font-family: 'Roboto Condensed' !important;
    font-weight: 500;
    
    
}


#form-main
{
    padding: 30px;
    padding-bottom: 2px;
    margin-bottom: 2rem;
}

#form-item
{
    padding-top: 4px;
    display: flex;
}

#form-item input 
{
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid #ccc;
    width: 100%;
    outline: none;
    padding: 10px;
    font-size: 0.9em;
  
}
#form-item input:hover, #form-item input:focus, #form-item input:active 
{
    border-bottom: 1px solid #2e9662;
}

/* #form-footer
{
    padding-top: 10px;
} */

#form-footer button.pay 
{
    font-family:'Poppins', sans-serif !important;;
    padding: 5px;
    margin-bottom: 20px;
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 300ms;
    background-color: #2e9662;
    color: white;
    font-weight: 900;
    cursor: pointer;

}

#pay{
    margin-top: 26px;

}

#form-footer button.pay:hover
{
    border: 1px solid #2e9662;
    background-color: transparent;
    color: #2e9662;
   
}

/*#footer-text
{
    text-align: center;
    color: grey;
    padding-bottom: 5px;
} */

/*#footer-text a
{
    text-decoration: underline;
    color: #808080;
} */

input[type="number"] {
    color: #333333;
    /* font-size: 1.5em; */
    text-align: center;
    font-weight: bold;
    font-size: medium;
}

button a,button a:hover{
    color: white;
    text-decoration: none;
}
button#returnLink
{
    font-family: 'Times New Roman', Times,serif;
    padding: 5px;
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 300ms;
    background-color: #2e9662;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

/*.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: transparent; 
    margin: 30px auto 0 auto;
    border: solid 2px #424242;
    border-top: solid 2px #1c89ff;
    border-radius: 50%;
    opacity: 0;
  }
  
  /*.check {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate3d(-4px,50px,0);
    opacity: 0;
  } */

 /* .loader.active {
    margin-top: -10px;
    animation: loading 1s ease-in-out;
    animation-duration: 1s;
    animation-iteration-count: 60;
    animation-fill-mode: forwards;
    -webkit-animation: loading 1s ease-in-out;
    
}
  
  /*.check.active {
    opacity: 1;
    transform: translate3d(-4px,4px,0);
    transition: all .5s cubic-bezier(.49, 1.74, .38, 1.74);
    transition-delay: .2s;
  }*/
  
 /* @keyframes loading {
    30% {
      opacity:1; 
    }
    
    85% {
      opacity:1;
      transform: rotate(1080deg);
      border-color: #262626;
    }
    100% {
      opacity:1;
      transform: rotate(1080deg);
       border-color: #1c89ff;
    }
  } */
  
  /*
  header{
    background-color: #F1F1F1;
   width: 100% !important;
   
  } */

  /*
  .navbar-collapse ul li{
    padding: 10px 20px 10px 0px;
    margin-left: -20px;

} */

/* --------------loader---------------- */


/* ----------fin loader---------------*/

#popup_sucess{
            position: fixed;
            top: 10%;
            right: -27%;
            width: 18%;
            height: 4%;
            visibility: visible;
            background-color: #2e9662;
            color: #f9f9f9;
            border: 0;
            font-size: 15px;
            font-weight: normal;
            border-radius: 4px;
            padding: 5px 40px;
            cursor: pointer;
            white-space: nowrap;
            -moz-box-shadow: 0px 0px 24px -1px rgba(56, 56, 56, 1);
            box-shadow: 0px 0px 8px -1px rgba(56, 56, 56, 1);
            -webkit-transition: all 550ms ease;
            transition: all 550ms ease;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;
            -moz-transition: all 550ms ease;
            -ms-transition: all 550ms ease;
            -o-transition: all 550ms ease;
}

#popup_failed{

    position: fixed;
    top: 10%;
    right: -27%;
    width: 18%;
    height: 4%;
    visibility: visible;
    background-color: #FA8072;
    color: #f9f9f9;
    border: 0;
    font-size: 15px;
    font-weight: normal;
    border-radius: 4px;
    padding: 5px 40px;
    cursor: pointer;
    white-space: nowrap;
    -moz-box-shadow: 0px 0px 24px -1px rgba(56, 56, 56, 1);
    box-shadow: 0px 0px 8px -1px rgba(56, 56, 56, 1);
    -webkit-transition: all 550ms ease;
    transition: all 550ms ease;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    -moz-transition: all 550ms ease;
    -ms-transition: all 550ms ease;
    -o-transition: all 550ms ease;

}

#message_request{
    padding: 5px;

}

#message{
    text-align: center;
    margin-top: -2px;
    margin-left: -12px;

}

.myImage{
   
    width: 150px;
    height: 150px;
    margin: auto;
    background-image: url("../../../img/paiement1.jpg");
    background-repeat: no-repeat;
    background-size: 110%;
}

#padlock{

    width: 17px;
    height: 37px;
    background-image: url("../../../img//padlock.png");
    background-repeat: no-repeat;
    background-size: 70%;
    margin-top: 19px;
   


}




.loader {
    position: relative;
    margin: 0 auto;
    width: 100px;
    content: '';
    padding-top: 10%;
  }
  
  
  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  
  @keyframes color {
    100%,
    0% {
      stroke: #d62d20;
    }
    40% {
      stroke:  #0057e7;
    }
  
    66% {
      stroke: #008744;
    }
  
    80%,
    90% {
      stroke:  #ffa700;
    }
  }
  
  

  .showbox {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5%;
  }

 /*------------message toast------------*/ 
  .hidden {
    display: none;
  }
  .show {
    display: block;
    text-align: center;
    color: #DF5948;
    }

    .shows {
      display: block;
      text-align: center;
      color: #2e9662;
      }

  .buttonLink {
    font-family: 'Poppins', sans-serif;
  }

/*---------- responsive ----------*/  

@media screen and (max-width:1025px) {
  .fixed-center {
    margin-top: 2rem;
  }
}

@media screen and (max-width:988px) {
  .fixed-center {
    margin-top: 15rem;
  }
}
@media screen and (max-width:979px) {
  .fixed-center {
    margin-top: 5rem;
  }
}



@media screen and (max-width:540px) {
  .fixed-center {
    margin-top: 1rem;
  }

}

@media screen and (max-width:415px) {
  .fixed-center {
    margin-top: 1rem;
  }

}


@media screen and (max-width:376px) {
  .fixed-center {
    margin-top: 2rem;
  }
 
}

@media screen and (max-width:360px) {
  .fixed-center {
    margin-top: 2rem;
  }
 
}

@media screen and (max-width:325px) {
  .fixed-center {
    margin-top: 2rem;
  }
  
}





/** leading**/


@keyframes ldio-73qv098kw9c {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-73qv098kw9c div {
  position: absolute;
  animation: ldio-73qv098kw9c 4.14s linear infinite;
  width: 128.8px;
  height: 128.8px;
  top: 16.1px;
  left: 16.1px;
  border-radius: 50%;
  box-shadow: 0 3.22px 0 0 #0a0a0a;
  transform-origin: 64.4px 66.01px;
}
.loadingio-spinner-eclipse-3evwe134a6t {
  width: 161px;
  height: 161px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-73qv098kw9c {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-73qv098kw9c div { box-sizing: content-box; }
/* generated by https://loading.io/ */
