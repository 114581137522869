


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
  font-family: 'Roboto', sans-serif !important;
  margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
}
.home_contents {
  position: relative;

}

 

.isrequired{
    color: rgb(255, 0, 0);
}
.error{
  
  color: #ff0000;
  font-weight: bold;

}
.griser{
   background-color: #2f9c65a1 !important;
}











