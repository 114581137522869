
.table-wrapper{
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 10vh auto;
    padding: 30px;
    box-shadow: 0px 5px 5px #ccc;
    font-family: 'Roboto', sans-serif;
}

.addSolution{
    text-align: end;
    
}
.addSolution .addSolutionBtn  {
    background: #2e9662;
    text-decoration: none;
    color: #fff;
    padding: 14px;
}
.table-title h3{
    text-align: left;
}
.tablee{
    width: 100%;
    border-collapse: collapse;
}
.tablee td,.tablee th {
    padding: 12px 15px;
    border:  1px solid #ddd;
    text-align: center;
}
.tablee  thead th {
    font-size:13px;
}

.tablee td .details-btn{

}
.tablee th{
    background-color: #fdfdfd;
    color: rgb(0, 0, 0);
}

.tablee tbody tr:nth-child(even){
    background-color: #f5f5f5;
}
.tablee  tr td .exchangeKeyCopy {
    width: 20px;
    height: 20px;
   
}
  
.tablee  tr td .exchangeKey {
   border: none;
   width: 80%;
   padding: 0;
   resize: none;
   border: #0000000c solid;
}
.tablee  tr td .exchangeKeyCopy img {
    cursor: pointer !important;
}
.tablee  tr .exchangeKey-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}





/* responsive*/

@media (max-width: 760px) {
    .tablee thead{
        display: none;
    }
    .tablee tfoot{
        display: none;
    }
    .addSolution .addSolutionBtn  {
        padding: 12px;
    }
    .table-title h3{
        font-weight: bold;
    }
    .tablee,.tablee tbody, .tablee tr, .tablee td{
        display: block;
        width: 100%;
    }
   .tablee tr{
        display: block;
        width: 100%;
        margin-top:30px ;
        border-top: #696565d4 6px solid;
    }
    .tablee td{
        text-align:  right;
        padding-left: 50%;
        position: relative;
        word-break: break-word;
        word-wrap: break-word;
    }
    .tablee td::before{
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        word-break: break-word;
        word-wrap: break-word;
    }
    .tablee  tr td .exchangeKey {
      
        width: 50%;
    
    
     }

  
}